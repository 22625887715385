export const WEB_ROUTES = {
  HOME: `/`,
  ABOUT: `/about`,
  TEAM: `/team`,
  SERVICES: `/services`,
  SERVICES_TURNKEY: `/services/turnkey-services`,
  SERVICES_HEALTH_FACILITY: `/services/health-facility-planning`,
  SERVICES_HEALTH_TECH: `/services/health-technology-planning-and-management`,
  SERVICES_COMMISSIONING: `/services/commissioning`,
  SERVICES_PROJECT_MANAGEMENT: `/services/programme-and-project-management`,
  SERVICES_FACILITIES_MANAGEMENT: `/services/facilities-maintenance-management`,
  PROJECTS_CECILIA: `/projects/cecilia`,
  PROJECTS_FRERE: `/projects/frere`,
  PROJECTS_MODIMOLLE: `/projects/modimolle`,
  PROJECTS_SIPETU: `/projects/sipetu`,
  PROJECTS_NGWELEZANA: `/projects/ngwelezana`,
  PROJECTS_SEBOKENG: `/projects/sebokeng`,
  PROJECTS_LIMPOPO: `/projects/limpopo`,
  PROJECTS_SILOAM: `/projects/siloam`,
  PROJECTS_MPUMALANGA: `/projects/mpumalanga`,
  PROJECTS_NELSON_MANDELA: `/projects/nelson-madela`,
  PROJECTS_CONDITION_ASSESSMENTS: `/projects/consition-assesssment`,
  PROJECTS_ROAD_TRAFFIC: `/projects/road-traffic`,
  PROJECTS_ROAD_TRAFFIC2: `/projects/road-traffic-2`,
  PROJECTS: `/projects`,
  PROJECTS_TYPE: `/projects/type`,
  NEWS: `/news`,
  NEWS_BHP_BILLITON: `/news/sakhiwo-completes-the-bhp-billiton-paediatric-centre`,
  NEWS_CECILIA_MAKIWANE: `/news/the-upgrade-of-the-cecilia-makiwane-hospital-in-mdantsane`,
  NEWS_AFRICA: `/news/sakhiwo-projects-throughout-the-rest-of-africa`,
  CAREERS: `/careers`,
  NEWSLETTER: `/subscribe-to-our-mailing-list`,
  LEGAL: `/legal`,
  TERMS: `/legal/terms-of-use`,
  PRIVACY_POLICY: `/legal/privacy-policy`,
  COOKIE_POLICY: `/legal/cookie-policy`,
  POPI: `/legal/popi`,
  COPYRIGHT_STATEMENT: `/legal/copyright-statement`,
  NOT_FOUND: `/404`,
};
