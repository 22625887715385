import { NavLink } from "react-router-dom";

export default function NewsItem({ article }) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      <div className="flex flex-col items-start ">
        <div className="relative w-full">
          <NavLink
            className="overflow-hidden inline-block"
            to={`/news/` + article?.slug}
          >
            <img
              src={article?._embedded["wp:featuredmedia"][0]?.source_url}
              alt={article?.title?.rendered}
              className="aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] transition duration-700 ease-in-out transform hover:scale-110"
            />
          </NavLink>

          <div className="absolute -bottom-5 left-0 bg-red-600 p-2 text-xs">
            <span className="text-white">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    new Date(article.date).getDate() +
                    " " +
                    monthNames[new Date(article.date).getMonth()] +
                    "<br/>" +
                    new Date(article.date).getFullYear(),
                }}
              />
            </span>
          </div>
        </div>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-6 text-2xl text-gray-900 hover:text-red-600">
              <NavLink to={`/news/` + article?.slug}>
                {article?.title?.rendered}
              </NavLink>
            </h3>

            <p
              className="mt-5 text-sm leading-6 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: article?.excerpt?.rendered,
              }}
            />

            <NavLink
              to={`/news/` + article?.slug}
              className="mt-5 float-left text-red-600 text-sm"
            >
              {" "}
              Read more{" "}
              <i className="fi fi-rr-arrow-right text-xl float-right ml-1.5"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
