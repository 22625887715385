import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function TeamItem({ member, memberMedia }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <li className="relative">
        <div
          className="cursor-pointer overflow-hidden block"
          onClick={() => setOpen(true)}
        >
          <img
            className="h-full object-cover transition duration-700 ease-in-out transform hover:scale-110"
            src={memberMedia?.source_url}
            alt={member?.title?.rendered}
          />
          <div className="absolute bottom-0 left-0 bg-gray-950/80 z-10 w-full p-6">
            <h3 className="text-xl font-semibold text-white">
              {member?.title?.rendered}
            </h3>
            <p className="text-sm text-gray-400">
              {member?.acf?.team_member_designation}
            </p>
          </div>
          <span className="bg-red-600 px-5 py-2 text-white absolute bottom-0 right-0 z-10 inline-block">
            <i className="fi fi-rr-arrow-right text-xl float-right mt-1 ml-1"></i>
          </span>
        </div>
      </li>

      <Dialog className="relative z-10" open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 pr-4 pt-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-600 focus:outline-none "
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="text-left ">
                  <DialogTitle
                    as="h3"
                    className="flex text-2xl font-semibold text-gray-900 md:gap-x-8 gap-x-4 items-center"
                  >
                    <img
                      className="w-40 object-cover"
                      src={memberMedia?.source_url}
                      alt={member?.title?.rendered}
                    />
                    <div>
                      {member?.title?.rendered}
                      <p className="text-red-600 text-sm">
                        {member?.acf?.team_member_designation}
                      </p>
                    </div>
                  </DialogTitle>

                  <div className="mt-2">
                    <p className="my-4">
                      {member?.acf?.team_member_qualifications}
                    </p>
                    <p
                      className="text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: member?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
