import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";

export default function ProjectsSingle() {
  const [triggerContact, setTriggerContact] = useState(false);

  const [project, setProject] = useState({});
  const singleProject = useMatch(WEB_ROUTES.PROJECTS + "/:project");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [projectMedia, setProjectMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "projects"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get project
        axios
          .get(
            CONTENT_ROUTES.PROJECTS("?slug=" + singleProject?.params?.project)
          )
          .then((res) => {
            let projectResponse = res.data?.length ? res.data[0] : {};
            setProject(projectResponse);

            //Get project media
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_PAGE(projectResponse?.id))
              .then((res) => {
                setProjectMedia(res.data);
              });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleProject]);

  return (
    <>
      <Header
        triggerContact={triggerContact}
        setTriggerContact={setTriggerContact}
      />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8"></div>

        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 w-full">
              <h1 className="pb-5 text-5xl font-semibold capitalize">
                {project?.title?.rendered}
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
            </div>
          </div>
        </section>

        {projectMedia?.find(
          (media) => media?.id === project?.featured_media
        ) && (
          <section className="bg-white">
            <div className="container mx-auto md:px-0 px-8">
              <img
                className="md:h-[550px] h-[350px] w-full object-cover"
                src={
                  projectMedia?.find(
                    (media) => media?.id === project?.featured_media
                  )?.source_url
                }
                alt={project?.title?.rendered}
              />
            </div>
          </section>
        )}

        <section className="bg-white">
          <div className="container mx-auto p-8 md:px-0 px-8 border-b-2">
            <div className="md:divide-x-2 md:divide-red-600">
              <div className="md:flex md:mb-0 mb-5 md:py-3 px-3 float-left">
                <span className="font-semibold pr-1">Location:</span>
                <span className="capitalize">
                  {project?.acf?.project_location}
                </span>
              </div>

              <div className="md:flex md:mb-0 mb-5 md:py-3 px-3 float-left">
                <span className="font-semibold pr-1">Project value:</span>
                <span className="capitalize">
                  {project?.acf?.project_value}
                </span>
              </div>

              <div className="md:flex md:mb-0 mb-5 md:py-3 px-3 float-left">
                <span className="font-semibold pr-1">Project completion:</span>
                <span className="capitalize">
                  {project?.acf?.project_completion}
                </span>
              </div>

              <div className="clear-both"></div>
            </div>
            <div className="md:mb-0 mb-5 md:py-3 px-3">
              {project?.acf?.project_completion_additional_info}
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto md:flex">
              <div className="md:w-96 w-full md:mb-0 mb-5">
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg pr-10">
                  <h2 className="text-5xl font-semibold">
                    Project description
                  </h2>
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <div className="max-w-xl">
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: project?.content?.rendered?.replace(
                            /<p.*?>/g,
                            "<p class='mb-5'>"
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto md:flex">
              <div className="md:w-96 w-full md:mb-0 mb-5">
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg pr-10">
                  <h2 className="text-5xl font-semibold">Scope of services</h2>
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <div className="max-w-xl">
                    {project?.acf?.project_scope?.map((scope, i) => (
                      <ul key={i} className="pl-0 list-none">
                        <li>
                          <div className="pl-0 text-red-600 text-2xl float-left mr-1">
                            <i class="fi fi-rr-bullet"></i>
                          </div>
                          <div className="md:float-left">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: scope,
                              }}
                            />
                          </div>
                          <div className="clear-both"></div>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <NavLink
              to={WEB_ROUTES.PROJECTS}
              className="float-right bg-red-600 px-5 py-2 text-white hover:bg-gray-950 md:mt-0 mt-10 inline-block"
            >
              More projects{" "}
              <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
            </NavLink>
            <div className="clear-both"></div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
