import { Fragment, useRef, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import NewsItem from "../components/news-item";
import FormNewsletter from "../components/form-newsletter";
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";
// import styles bundle
import "swiper/css/bundle";

export default function Home() {
  const [page, setPage] = useState({});
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerMedia, setBannerMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "home"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          "&_embed&order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    //Get banners
    axios
      .get(CONTENT_ROUTES.BANNERS("?order_by=menu_order&order=asc"))
      .then((res) => {
        setBanners(res.data);

        //Get banner media
        let media = [];

        res.data?.map((banner, i) => {
          if (banner?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(banner?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setBannerMedia((prevState) => [...prevState, media[i]]);
              });
          return banner?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="md:px-10 md:pb-10">
          <div className="container mx-auto relative banner">
            <div className="absolute md:right-10 right-5 md:bottom-10 bottom-14 z-10">
              <dl className="grid grid-cols-2">
                <NavLink
                  to={WEB_ROUTES.PROJECTS}
                  className="border-l-2 border-red-600 md:py-8 md:px-12 py-4 px-4 bg-black/40 hover:bg-red-600"
                >
                  <dd className="text-4xl font-bold leading-10 tracking-tight text-white">
                    {page?.acf?.banner_block1_counter}
                  </dd>
                  <dt className="text-sm leading-6 text-white">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.acf?.banner_block1_text.replace(
                          /\n/g,
                          "<br />"
                        ),
                      }}
                    />
                  </dt>
                </NavLink>
                <NavLink
                  to={WEB_ROUTES.ABOUT}
                  className="border-l-2 border-red-600 md:py-8 md:px-12 py-4 px-4 bg-black/40 hover:bg-red-600"
                >
                  <dd className="text-4xl font-bold leading-10 tracking-tight text-white">
                    {page?.acf?.banner_block2_counter}
                  </dd>
                  <dt className="text-sm leading-6 text-white">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.acf?.banner_block2_text.replace(
                          /\n/g,
                          "<br />"
                        ),
                      }}
                    />
                  </dt>
                </NavLink>
              </dl>
            </div>

            <Swiper
              ref={sliderRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              direction={"horizontal"}
              loop={true}
              speed={1000}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {banners?.map((banner, i) => (
                <SwiperSlide key={i} className="swiper-slide">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${
                        bannerMedia?.find(
                          (media) => media.id === banner?.featured_media
                        )?.source_url
                      })`,
                    }}
                  ></div>

                  <div className="absolute left-0 top-0 z-10 w-full h-full bg-gradient-to-r from-black md:to-50% to-100% opacity-50"></div>

                  <div className="z-20 w-full">
                    <div className="md:w-[500px] w-full md:ml-20 ml-5 md:mr-0 mr-5 -mt-20">
                      <div className="p-2 mb-2">
                        <h2 className="text-white text-4xl pb-10">
                          {banner?.title?.rendered}
                        </h2>
                        <NavLink
                          to={banner?.acf?.banner_cta?.url}
                          className="bg-red-600 px-5 py-2 text-white hover:bg-gray-950 inline-block"
                        >
                          {banner?.acf?.banner_cta?.title}{" "}
                          <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div
              onClick={handlePrev}
              className="swiper-button-prev bg-gray-950 p-2 hover:bg-red-600 flex align-middle justify-center"
            >
              <i class="fi fi-ts-arrow-small-left text-4xl mt-1"></i>
            </div>
            <div
              onClick={handleNext}
              className="swiper-button-next bg-gray-950 p-2 hover:bg-red-600"
            >
              <i class="fi fi-ts-arrow-small-right text-4xl mt-1"></i>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute w-1/2 h-full bg-gray-950 left-0 top-0 -z-10"></div>
          <div className="container mx-auto ">
            <h1
              className="md:px-0 md:pr-8 px-8 py-16 text-4xl font-semibold text-white bg-gray-950"
              dangerouslySetInnerHTML={{
                __html: page?.content?.rendered
                  ?.replace(/<p.*?>*?<\/p>/g, "")
                  ?.replace(/<h2.*?>/g, ""),
              }}
            />
          </div>

          <div className="relative">
            <div className="container mx-auto ">
              <div className="lg:flex bg-gray-950 ">
                <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:inset-y-0 xl:right-1/2 xl:w-1/2 md:px-0 px-8">
                  <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
                    <img
                      className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
                      src={
                        pageMedia?.find(
                          (media) => media?.id === page?.featured_media
                        )?.source_url
                      }
                      alt={page?.title?.rendered}
                    />
                  </div>
                </div>
                <div className="md:px-6 px-8 md:pt-0 pt-8">
                  <div className="mx-auto max-w-lg pb-24 md:pl-7 pl-0 lg:w-full lg:flex-none">
                    <div
                      className="mb-5 text-neutral-400"
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered
                          ?.replace(/<h2.*?>*?<\/h2>/g, "")
                          ?.replace(/<p>/g, "<p class='pb-6'>"),
                      }}
                    />

                    <NavLink
                      to={WEB_ROUTES.ABOUT}
                      className="bg-red-600 px-5 py-2 text-white hover:bg-gray-500 inline-block"
                    >
                      Learn more{" "}
                      <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="mx-auto container md:px-0 px-8">
            <div className="mx-full">
              <h2 className="text-4xl font-semibold float-left">
                Latest news and insights
              </h2>
              <NavLink
                to={WEB_ROUTES.NEWS}
                className="border-red-600 border-b-[3px] float-right px-5 py-2 md:inline-block hidden"
              >
                More news{" "}
                <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
              </NavLink>
              <div className="clear-both"></div>
            </div>
            <div className="mx-auto md:mt-16 mt-5 grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {posts?.slice(0, 3).map((article, i) => (
                <Fragment key={i}>
                  <NewsItem article={article} />
                </Fragment>
              ))}
            </div>
            <div className="mx-full">
              <NavLink
                to={WEB_ROUTES.NEWS}
                className="border-red-600 border-b-2 float-right px-5 py-2 inline-block md:hidden"
              >
                More news{" "}
                <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
              </NavLink>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section className="bg-zinc-600 py-20">
          <div className="mx-auto container md:px-0 px-8">
            <div className="grid grid-cols-1 gap-10 lg:grid-cols-12 lg:gap-8">
              <div className="max-w-xl text-white sm:text-4xl lg:col-span-7">
                <h2 className="text-4xl font-semibold mb-5">Stay up to date</h2>
                <p className="text-2xl pl-10 border-l-2 border-red-600">
                  You can sign up for our mailing list for all the latest
                  Sakhiwo news and related information by completing the
                  subscription form.
                </p>
                <p className="mt-4 text-sm text-gray-300">
                  You can unsubscribe from our mailing list at any time using
                  the link in our emails. Learn about how we process your
                  personal data in our{` `}
                  <NavLink
                    to={WEB_ROUTES.PRIVACY_POLICY}
                    className="text-white hover:underline"
                  >
                    privacy&nbsp;policy
                  </NavLink>
                  .
                </p>
                <p className="mt-4 text-sm text-gray-300">
                  This form is protected by reCAPTCHA. Google's {` `}
                  <a
                    href="https://policies.google.com/privacy?hl=en-GB"
                    target="_blank"
                    className="text-white hover:underline"
                  >
                    Privacy Policy
                  </a>
                  {` `}
                  and
                  {` `}
                  <a
                    href="https://policies.google.com/terms?hl=en-GB"
                    target="_blank"
                    className="text-white hover:underline"
                  >
                    Terms of Service
                  </a>
                  {` `}
                  apply.
                </p>
              </div>
              <div className="w-full lg:col-span-5">
                <FormNewsletter bgType={`dark`} />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
