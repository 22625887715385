function validEmail(value) {
  var emailFilter =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return emailFilter.test(value);
}

function validContact(value) {
  let contact = /^\+?[0-9]+$/;
  return contact.test(value);
}

export const useFormValidation = () => {
  function validFormDetails(
    formFields,
    formDetails,
    setInvalidFormDetails,
    setFormError
  ) {
    let validFormFields = true;
    setFormError("");

    formFields.forEach((field, i) => {
      if (validFormFields && formDetails[field.name] === "selected") {
        setInvalidFormDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));

        setFormError("Please select " + field.display + ".");
        validFormFields = false;
      } else if (
        validFormFields &&
        (formDetails[field.name] === undefined ||
          formDetails[field.name] === null ||
          formDetails[field.name].length === 0 ||
          (typeof formDetails[field.name] === "string" &&
            formDetails[field.name].trim() === ""))
      ) {
        setInvalidFormDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));

        setFormError("Please enter " + field.display + ".");
        validFormFields = false;
      } else if (
        validFormFields &&
        (((field.name.indexOf("phone_number") !== -1 ||
          field.name.indexOf("contact_number") !== -1) &&
          formDetails[field.name].length &&
          !validContact(formDetails[field.name])) ||
          (field.name.indexOf("email") !== -1 &&
            formDetails[field.name].length &&
            !validEmail(formDetails[field.name])))
      ) {
        setInvalidFormDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));
        setFormError(
          "Please enter a valid  " + field.display + " (no spaces)."
        );
        validFormFields = false;
      } else {
        setInvalidFormDetails((prevState) => ({
          ...prevState,
          [field.name]: false,
        }));
      }
    });

    return validFormFields;
  }

  return { validFormDetails };
};
